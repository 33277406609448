/* ============================================ */
/* Styles */
/* ============================================ */

/* Base */
/* ============================================ */

@import 'tailwindcss/base';
@import url('./base/fonts');
@import url('./base/base');

/* Components */
/* ============================================ */

@import 'tailwindcss/components';

/* Utilities */
/* ============================================ */

@import 'tailwindcss/utilities';

/* Shame - https://media.giphy.com/media/vX9WcCiWwUF7G/giphy.gif */
/* ============================================ */

.email-input.invalid {
  @apply bg-red-100 rounded;
}

html {
  scroll-behavior: smooth;
}
