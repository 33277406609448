/* ============================================ */
/* Base Styles */
/* ============================================ */

@layer base {
  html {
    -webkit-tap-highlight-color: transparent;
    scroll-behavior: smooth;
  }
}

/* For Webkit-based browsers (Chrome, Safari and Opera) */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}